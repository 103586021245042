<template>
    <div class="contenido">
        <ImageHeader />
        <div class="is-10 container-data container" style="padding-top: 30px">
            <div class="columns">
                <div class="is-6 column has-text-left go-back" @click="$router.go(-1)">
                    <p class="title white bold">
                        <font-awesome-icon class="icono" :icon="['fal', 'chevron-left']" />
                        {{$t('EVALUATOR.STORY.TITLE')}} {{ this.$route.params.relatoId }} - <span
                            class="title-m regular">{{ this.$route.params.usuarioId }}</span>
                    </p>
                </div>
                <div class="is-3 is-offset-3 column has-text-right">
                    <button class="btn-go-to-list btn button">
                        <router-link to="/evaluador"><span>
                                <font-awesome-icon class="icono" :icon="['fal', 'list']" /> <span
                                    class="text-l black">{{$t('EVALUATOR.GO_PROFILE')}}</span>
                            </span></router-link>
                    </button>
                </div>
            </div>
            <div class="container is-10 data">
                <div class="is-10 is-offset-1 column has-text-left" style="padding-top: 1em;">
                    <span class="subtitle bold black">{{$t('EVALUATOR.STORY.TITLE_STORY')}}</span>
                </div>
                <div class="is-10 is-offset-1 column has-text-left" style="padding-top: 1em;">
                    <p class="text-l background-gray">{{evaluacion.titulo}}</p>
                </div>
                <div class="is-10 is-offset-1 column has-text-left" style="padding-top: 1em;">
                    <span class="subtitle bold black">{{$t('EVALUATOR.STORY.TEXT_STORY')}}</span>
                </div>
                <div class="is-10 is-offset-1 column has-text-left" style="padding-top: 1em;">
                    <p class="text-l background-gray" v-html="evaluacion.redaccion"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ImageHeader from "@/components/ImageHeader.vue";
    import { FASES } from "../constants.js";

    export default {
        name: "RelatoOtroEvaluador",
        components: {
            ImageHeader
        },
        data() {
            return {
                evaluacion: {},
                fase: ""
            };
        },
        created() {
            this.$store.dispatch("getFase").then(fases => {
                fases = fases.filter(obj => obj.activa);
                if (fases.some(obj => obj.codigo == FASES.EVALUACION_1)) {
                    this.fase = FASES.EVALUACION_1;
                }
                else if (fases.some(obj => obj.codigo == FASES.EVALUACION_2)) {
                    this.fase = FASES.EVALUACION_2;
                }
                this.$store
                    .dispatch("getEvaluacion", {
                        fase: this.fase,
                        relato_id: this.$route.params.relatoId,
                        usuario_id: this.$route.params.usuarioId,
                    })
                    .then((evaluacion) => {
                        evaluacion.redaccion = evaluacion.redaccion.replace(/\n/g, '<br>');
                        this.evaluacion = evaluacion;
                        this.$store.commit("setShowSpinner", false);
                    })
                    .catch((error) => {
                        this.$store.commit("setShowSpinner", false);
                    });
            });
        },
        methods: {
        }
    };
</script>

<style scoped lang="scss">
    @import '../styles/variables.scss';


    .container-data {
        margin-top: -220px;
        ;
        padding-bottom: 100px;
    }

    .container.data {
        background-color: white;
        padding-bottom: 35px;
    }

    .btn-go-to-list {
        padding: 20px;
        background-color: white;
        border-radius: 20px;

        .icono {
            color: $primary;
            margin-right: 15px;
        }
    }

    .separacion {
        width: 100%;
    }

    .background-gray {
        padding: 20px;
        border-radius: 10px;
        background-color: $background-box;
    }

    .profileImage {
        max-width: 50px;
        max-height: 50px;
        border-radius: 50%;
        margin-right: 20px;
        margin-left: 20px;
    }

    .go-back:hover {
        cursor: pointer;
    }
</style>